@import "@/utils/variables";
@import "@/utils/mixins";

.header {
	background: $deep-green;
	position: relative;
	margin-bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-bottom: 5px;
	padding-top: 5px;
	height: 70px;
	transition:
		height 0.4s,
		min-height 0.8s;
	z-index: 5;
	min-height: 70px;
	width: 100%;

	@include desktop() {
		position: relative;
		height: auto;
		padding-top: 10px;
		width: 100%;
		min-height: 80px;
	}

	&.headerDropped {
		height: calc(100vh - 20px);

		@include desktop() {
			height: auto;
		}

		// @include desktop() {
		// 	height: 300px;
		// }
	}

	&.fixedHeader {
		position: fixed;
	}

	&.funnelHeader {
		margin-bottom: 40px;
		min-height: 75px;
		padding-top: 5px;
		height: auto;

		@include tabletLarge() {
			min-height: 110px;
			padding-top: 30px;
			margin-bottom: 70px;
		}

		@include desktop() {
			margin-bottom: 50px;
		}

		@include desktopLarge() {
			margin-bottom: 70px;
		}

		&.spaceTop {
			padding-top: 40px;

			@include tabletLarge() {
				padding-top: 30px;
			}
		}

		.container {
			margin-bottom: 5px;

			@include tabletLarge() {
				margin-bottom: 30px;
			}
		}
	}

	&.heroHeader {
		min-height: 200px;
	}

	&.heroHeader,
	&.heroMenuHeader {
		padding-bottom: 20px;
		padding-top: 5px;
		height: auto;
		min-height: 100px;
		margin-bottom: 0px;
		transition:
			height 0.4s,
			min-height 0.4s;

		&.headerDropped {
			min-height: calc(100vh - 20px);
		}

		@include tablet() {
			margin-bottom: 0px;
			padding-top: 20px;
		}

		@include desktop() {
			padding-top: 30px;
			margin-bottom: 0px;

			.container {
				margin-bottom: 20px;
			}
		}

		&.spaceTop {
			padding-top: 40px;

			@include tabletLarge() {
				padding-top: 30px;
			}

			@include desktop() {
				padding-top: 30px;
			}
		}

		&.heroHeaderInverse {
			padding-bottom: 40px;

			@include tablet() {
				padding-top: 40px;
			}
		}

		h1 {
			color: $white;
		}

		p {
			color: $white;
			font-weight: $normal-base;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;

				@include tablet() {
					margin-bottom: 10px;
				}
			}
		}

		a {
			font-size: 1.8rem;
			color: $white;
			font-weight: $normal-base;

			&:hover {
				color: $white;
			}
		}
	}

	&.heroMenuHeader {
		padding-top: 5px;

		@include tablet() {
			padding-top: 10px;
		}

		.headerBtn a {
			color: $deep-green;
		}
	}

	&.loadingStep {
		z-index: $z-index-above-all;
		min-height: calc(100vh + 20px);
		position: fixed;
		top: 0;
		width: 100%;

		.content {
			opacity: 0;
		}
	}

	.content {
		opacity: 1;
		transition: opacity 0.4s;
		z-index: 1;
	}

	.container {
		z-index: 3;
	}
}

.headerImages {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 1;
	pointer-events: none;

	&.headerImagesOverlap {
		overflow: visible;
	}

	.headerImage {
		position: absolute;
		max-width: 50px;
		margin-bottom: 0;

		&1 {
			top: 55px;
			left: -15px;
			max-width: 45px;

			@include tablet() {
				top: 75px;
				left: -15px;
				max-width: 60px;
			}

			@include tabletLarge() {
				top: 75px;
				left: -5px;
				max-width: 55px;
			}

			@include desktop() {
				top: 85px;
				left: -5px;
				max-width: 70px;
			}
		}

		&2 {
			top: -40px;
			max-width: 100px;
			left: -15px;

			@include tablet() {
				top: -30px;
				max-width: 115px;
				left: 10px;
			}

			@include tabletLarge() {
				top: -35px;
				max-width: 120px;
				left: 25px;
			}

			@include desktop() {
				top: -35px;
				max-width: 140px;
				left: 25px;
			}
		}

		&3 {
			top: 65px;
			left: 25px;
			max-width: 75px;
			transform: rotate(50deg);

			@include tablet() {
				top: 80px;
				left: 75px;
				max-width: 90px;
			}

			@include tabletLarge() {
				top: 90px;
				left: 100px;
				max-width: 100px;
			}

			@include desktop() {
				top: 100px;
				left: 115px;
				max-width: 115px;
			}
		}

		&4 {
			top: -25px;
			left: 75px;
			max-width: 60px;

			@include tablet() {
				top: -10px;
				left: 135px;
				max-width: 60px;
			}

			@include tabletLarge() {
				top: -5px;
				left: 170px;
				max-width: 60px;
			}

			@include desktop() {
				top: -5px;
				left: 190px;
				max-width: 65px;
			}
		}

		&5 {
			right: -20px;
			max-width: 80px;
			top: 80px;
			transform: rotate(-10deg);

			@include tablet() {
				right: -35px;
				max-width: 105px;
				top: 80px;
			}

			@include tabletLarge() {
				right: -35px;
				max-width: 100px;
				top: 90px;
			}

			@include desktop() {
				right: -35px;
				max-width: 115px;
				top: 90px;
			}
		}

		&6 {
			max-width: 50px;
			top: -15px;
			right: 60px;

			@include tablet() {
				max-width: 75px;
				top: -15px;
				right: 100px;
			}

			@include tabletLarge() {
				max-width: 75px;
				top: -10px;
				right: 110px;
			}

			@include desktop() {
				max-width: 90px;
				top: -10px;
				right: 115px;
			}
		}

		&7 {
			max-width: 65px;
			top: 10px;
			right: -11px;
			transform: rotate(15deg);

			@include tablet() {
				max-width: 75px;
				top: 5px;
				right: -15px;
			}

			@include tabletLarge() {
				max-width: 80px;
				top: 5px;
				right: -15px;
			}

			@include desktop() {
				max-width: 85px;
				top: 5px;
				right: -15px;
			}
		}

		&8 {
			max-width: 50px;
			top: 45px;
			right: 58px;
			transform: rotate(370deg);

			@include tablet() {
				max-width: 65px;
				top: 80px;
				right: 90px;
			}

			@include tabletLarge() {
				max-width: 75px;
				top: 80px;
				right: 90px;
			}

			@include desktop() {
				max-width: 75px;
				top: 95px;
				right: 120px;
			}
		}

		&9 {
			display: none;
			transform: rotate(-10deg);

			@include tablet() {
				right: -25px;
				max-width: 50px;
				top: 320px;
			}

			@include tabletLarge() {
				display: block;
				right: -25px;
				max-width: 55px;
				top: 290px;
			}

			@include desktop() {
				right: -25px;
				max-width: 60px;
				top: 320px;
			}
		}

		&10 {
			display: none;
			transform: rotate(0deg);

			@include tablet() {
				right: 15px;
				max-width: 130px;
				top: 200px;
			}

			@include tabletLarge() {
				display: block;
				right: 15px;
				max-width: 110px;
				top: 190px;
			}

			@include desktop() {
				right: 15px;
				max-width: 140px;
				top: 200px;
			}
		}

		&11 {
			display: none;
		}

		&12 {
			display: none;
			transform: rotate(240deg);

			@include tablet() {
				right: 115px;
				max-width: 40px;
				top: 180px;
			}

			@include tabletLarge() {
				display: block;
				right: 105px;
				max-width: 50px;
				top: 170px;
			}

			@include desktop() {
				right: 115px;
				max-width: 50px;
				top: 180px;
			}
		}

		&13 {
			display: none;
			transform: scaleX(-1);

			@include tablet() {
				top: 245px;
				left: -50px;
				max-width: 100px;
			}

			@include tabletLarge() {
				display: block;
				top: 245px;
				left: -60px;
				max-width: 100px;
			}

			@include desktop() {
				top: 265px;
				left: -60px;
				max-width: 110px;
			}
		}

		&14 {
			display: none;
			transform: rotate(-60deg);

			@include tablet() {
				top: 175px;
				max-width: 55px;
				left: 30px;
			}

			@include tabletLarge() {
				display: block;
				top: 155px;
				max-width: 55px;
				left: 25px;
			}

			@include desktop() {
				top: 175px;
				max-width: 65px;
				left: 30px;
			}
		}

		&15 {
			display: none;
		}

		&16 {
			display: none;
			transform: rotate(20deg);

			@include tablet() {
				top: 240px;
				left: 90px;
				max-width: 55px;
			}

			@include tabletLarge() {
				display: block;
				top: 210px;
				left: 85px;
				max-width: 65px;
			}

			@include desktop() {
				top: 240px;
				left: 90px;
				max-width: 65px;
			}
		}
	}
}

.headerWrapper {
	position: relative;
	min-height: 70px;

	@include desktop() {
		min-height: 80px;

		&.bottomOffset {
			margin-bottom: 30px;
		}
	}

	&.loadingStep {
		margin-bottom: 155px;

		@include tabletLarge() {
			margin-bottom: 130px;
		}
	}
}

.bottomBorder {
	position: absolute;
	top: calc(100% - 2px);
	height: 25px;
	width: 100%;
	background-image: url("/images/header-bottom.svg");
	background-repeat: repeat;
	background-size: 20px 26px;
	transform: translateY(-13px);
	z-index: 2;

	&.bottomBorderInverse {
		height: 26px;
		//background-color:$deep-green;
		top: calc(100% - 12px);
		background-image: url("/images/letter-off-white-i.svg");
		background-repeat: repeat-x;
		background-position-y: 12px;
	}

	&::after {
		content: " ";
		clear: both;
		position: absolute;
	}
}

.discount {
	margin: auto 0;
	position: absolute;
	right: 0;

	&Wrapper {
		grid-column: 6;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include tablet() {
			grid-column: 9 / span 4;
		}

		@include tabletLarge() {
			grid-column: 9 / span 4;
		}
	}
}

.headerLogoContainer {
	grid-column: 3 / span 2;
	text-align: center;

	@include mobileLarge() {
		grid-column: 3 / span 2;
	}

	@include tablet() {
		grid-column: 5 / span 4;
	}

	@include desktop() {
		text-align: left;
		grid-column: 1 / span 1;
	}
}

.header__logo {
	text-indent: -9999px;
	overflow: hidden;
	width: 110px;
	display: inline-block !important;
	height: 60px;
	background-image: url(/images/logo.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	margin: 0 auto;

	@include tablet() {
		transform: none;
		left: 0;
		margin-left: 0;
	}
}

.funnelHeader,
.heroHeader,
.overlayHeader,
.heroOverlayHeader {
	@include tablet() {
		position: relative;
	}

	.headerLogoContainer {
		grid-column: 2 / span 4;
		text-align: center;

		@include mobileLarge() {
			grid-column: 3 / span 2;
		}

		@include tablet() {
			text-align: center;
			grid-column: 5 / span 4;
		}
	}

	.header__logo {
		width: 110px;
		height: 60px;

		@include tabletLarge() {
			width: 140px;
			height: 70px;
		}
	}
}

// Burger Icon
.mobileNavBtnContainer {
	grid-column: 1 / span 1;

	@include desktop() {
		display: none;
	}
}

// ACCOUNT RIGHT ICON
.headerRightButton {
	grid-column: 6 / span 1;

	@include tablet() {
		grid-column: 11 / span 1;
	}

	@include desktop() {
		display: none;
	}
}

.overlayHeader {
	position: fixed;
	top: 0;
	height: 100vh;
	height: 100dvh;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 0;

	.headerRightButton {
		display: none;
	}

	.headerLogoContainer {
		margin-top: 20px;
	}
}

.heroOverlayHeader {
	position: fixed;
	top: 0;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 0;
	z-index: 9999;

	.headerRightButton {
		display: none;
	}

	.headerLogoContainer {
		margin-top: 20px;
	}
}

.headerAccountUserButton {
	color: $white;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	width: 100%;
}

.header__nav-button {
	width: 45px;
	height: 45px;
	padding: 5px;
	outline: none;
	position: relative;
	text-indent: -9999px;
	overflow: hidden;
	display: block;
	margin: 10px auto 0;

	span {
		height: 3px;
		background-color: $white;
		position: absolute;
		width: 30px;
		display: block;
		transition:
			top 0.2s 0.2s,
			opacity 0s 0.2s;
		top: 7px;
		left: 7px;
	}

	&:after,
	&:before {
		content: "";
		width: 30px;
		position: absolute;
		height: 3px;
		left: 7px;
		top: calc(50% - 6px);
		background-color: $white;
		transition:
			top 0.2s 0.2s,
			transform 0.2s;
	}

	&:after {
		top: calc(100% - 18px);
	}

	&.is-active {
		span {
			top: calc(50% - 6px);
			opacity: 0;
			transition:
				top 0.2s,
				opacity 0s 0.2s;
		}

		&:before {
			transform: rotate(45deg);
			transition:
				top 0.2s,
				transform 0.2s 0.2s;
		}

		&:after {
			top: calc(50% - 6px);
			transform: rotate(-45deg);
			transition:
				top 0.2s,
				transform 0.2s 0.2s;
		}
	}
}

.mobileOnly {
	@include desktop() {
		display: none !important;
	}
}

//NAV
.header {
	nav {
		@include desktop() {
			display: inline-block;
		}
	}

	// Mobile nav
	.header__mobile-nav {
		position: absolute;
		width: 100%;
		z-index: 10;
		top: 60px;
		bottom: 20px;
		padding: $gutter;
		right: 100%;
		transition: right 0.4s;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		@include desktop() {
			grid-column: 2 / span 10;
			position: static;
			width: auto;
			height: auto;
			background-color: transparent;
			top: auto;
			padding: 0;
			right: auto;
			transition: none;
			overflow: visible;
			display: inline-block;
			width: 100%;
		}

		&.isShort {
			@include desktop() {
				grid-column: 2 / span 9;
			}
		}

		&.headerMobileNavNoBasket {
			@include desktop() {
				grid-column: 2 / span 11;
			}
		}

		&.headerMobileNavAccount {
			display: none;

			@include desktop() {
				display: block;
			}
		}

		&.is-active {
			right: 0;

			@include desktop() {
				right: auto;
			}
		}
	}

	// General nav styling
	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 $gutter;
		font-weight: $bold-base;

		@include desktop() {
			display: inline-block;
			margin-bottom: 0;
			@include font-size(18);
		}

		li {
			user-select: none;
			list-style: none;
			width: 100%;
			padding: 0;
			margin: 0;

			@include desktop() {
				width: auto;
				display: inline-block;
			}

			a,
			button {
				color: $white;
				width: 100%;
				font-weight: $normal-base;
				text-align: left;

				@include desktop() {
					width: auto;
					padding-left: 10px;
					padding-right: 10px;
					font-size: 1.8rem;
				}

				@include desktopLarge() {
					padding-left: 15px;
					padding-right: 15px;
				}

				> div {
					text-align: left;

					@include desktop() {
						text-align: center;
					}

					span {
						@include desktop() {
							margin-left: 5px !important;
						}
					}
				}

				&:hover {
					color: $white;
				}
			}

			ul {
				height: 0;
				min-height: 0;
				overflow: hidden;
				margin: 0;
				padding: 0px;

				@include desktop() {
					position: absolute;
					width: 100%;
					left: 0;
					height: auto;
					top: 0px;
					text-align: center;
					z-index: 0;
				}

				&.is-active {
					height: auto;
					padding-left: 0px;

					@include desktop() {
						padding: $gutter 0;
					}
				}

				li a,
				li button {
					@include desktop() {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}

	// Primary nav styling
	.header__nav-primary {
		position: relative;

		@include desktop() {
			margin-top: 5px;
			position: static;
		}

		li {
			margin-top: 10px;

			@include desktop() {
				margin-top: 0;
			}
		}

		.header__nav-primary-child {
			margin: 0;
			text-align: left;
			background-color: $deep-green;
			position: absolute;
			z-index: 2;
			top: 0;
			width: calc(100% + 20px);
			height: calc(100vh - 120px);
			max-height: calc(100vh - 100px);
			overflow: auto;
			transition: left 0.4s;
			left: calc(100% + 20px);
			padding-left: 20px;
			padding-bottom: 40px;

			&:after {
				position: absolute;
				top: calc(100% - 2px);
				height: 25px;
				width: 100%;
				background-image: url("/images/header-bottom.svg");
				background-repeat: repeat;
				background-size: 20px 26px;
				transform: translateY(-13px);
				z-index: 2;
				left: 0;

				@include desktop() {
					content: "";
				}
			}

			@include desktop() {
				left: 0;
				top: 80px;
				padding-top: 20px;
				display: none;
				height: auto;
				min-height: auto;
				max-height: none;
				overflow: visible;
			}

			&.is-active {
				left: -20px;

				@include desktop() {
					left: 0;
					display: block;
				}

				li {
					@include desktop() {
						margin: 0 0px;
					}

					a {
						opacity: 1;
					}
				}
			}

			li {
				@include desktop() {
					grid-column: span 3;
				}

				&.headerNavPrimaryChildHeading {
					@include desktop() {
						display: none;
					}
				}

				&:first-child {
					margin-top: 0;
				}

				> button {
					@include desktop() {
						font-weight: $bold-base;
					}
				}

				.headerNavPrimaryChildSecondary {
					position: relative;

					&.is-active {
						margin-top: 10px;

						@include desktop() {
							margin-top: 0;
							padding-top: 0;
							padding-bottom: 0;
						}
					}

					li {
						margin-left: 10px;

						@include desktop() {
							margin-left: 0;
							display: block;
							text-align: left;
						}

						a:hover {
							@include desktop() {
								text-decoration: underline;
							}
						}
					}
				}
			}

			li a {
				opacity: 0;

				@include desktop() {
					text-align: center;
				}
			}

			span.header__nav-drop-text {
				display: block;
				font-size: 1.4rem;
				margin-top: 5px;
				margin-bottom: 5px;

				@include desktop() {
					margin-top: 10px;
					margin-bottom: 0;
					text-align: center;
					font-size: 1.6rem;
				}
			}
		}
	}

	// Secondary nav styling
	.header__nav-secondary {
		position: relative;
		list-style-type: none;
		margin-top: auto;
		// Needed to clear the navigation bar on older iphones
		margin-bottom: 100px;

		@include desktop() {
			position: relative;
			right: auto;
			top: auto;
			float: right;
			margin-top: 5px;
			margin-bottom: 0;
		}

		@include desktop() {
			font-size: 1.6rem;
		}

		li {
			list-style-type: none;
		}
	}
}

.headerBtn {
	position: relative;
	grid-column: 5 / span 2;
	top: 5px;
	text-align: right;

	@include tablet() {
		grid-column: 11 / span 2;
	}

	button,
	a {
		max-width: 80px;
		line-height: 1.1;

		@include tablet() {
			max-width: none;
			line-height: 1.3;
		}
	}
}
